<template>
	<div>
		<van-form ref="planForm" input-align="right" error-message-align="right" :scroll-to-error="true">
			<van-field label="保障期限" :disabled="fixedEnableChange === 0" :value="formShow.priceId" label-class="cell_title" value-class="cell_value" :border="false" readonly is-link clickable name="picker" placeholder="请选择保障期限" @click="handlePopup('priceIdPop')" :rules="rules.priceId" />
			<van-field label="起保日期" :disabled="fiexdEnableDate" :value="formShow.start" label-class="cell_title" value-class="cell_value" :border="false" readonly is-link clickable name="calendar" placeholder="请选择起保日期" @click="handlePopup('startDatePop')" :rules="rules.start" />
			<van-field label="终保日期" :disabled="fiexdEnableDate" :value="formShow.end" label-class="cell_title" value-class="cell_value" :border="false" readonly is-link clickable name="calendar" placeholder="请选择终保日期" @click="handlePopup('endDatePop')" :rules="rules.end" />
			<!-- 起保日期 -->
			<van-calendar v-model="startDatePop" @confirm="startDateCheck" :default-date="new Date(form.start)" :min-date="new Date(this.startMinDisabledTimeStart)" :max-date="new Date(this.startMaxDisabledTimeEnd)" color="#2594EF" :formatter="$base.calendarFormatter" />
			<!-- 终保日期 -->
			<van-calendar v-model="endDatePop" @confirm="endDateCheck" :default-date="new Date(form.end)" :min-date="new Date(this.endMinDisabledTimeStart)" :max-date="new Date(this.endMaxDisabledTimeEnd)" color="#2594EF" :formatter="$base.calendarFormatter" />
			<!-- 保障期限 -->
			<van-popup v-model="priceIdPop" position="bottom" :style="{ height: '30vh' }">
				<van-picker title="保障期限" show-toolbar value-key="viewTime" :default-index="defaultIndex" :visible-item-count="3" :columns="priceList" @confirm="onConfirm" @cancel="priceIdPop = false" />
			</van-popup>
		</van-form>
	</div>
</template>

<script>
import moment from 'moment/moment';
import { Form, Field, Calendar, Popup, Picker, Button } from 'vant';
import { http_getProductComboConfig } from '@/request/insureV2.js';
export default {
	inject: ['proId', 'comboId', 'productInfo'],
	components: {
		[Form.name]: Form,
		[Field.name]: Field,
		[Calendar.name]: Calendar,
		[Popup.name]: Popup,
		[Picker.name]: Picker,
		[Button.name]: Button,
	},
	props: {
		// 默认保障期限是否可以修改 0-不可改 1-可改
		fixedEnableChange: {
			type: Number,
			required: false,
			default: 1,
		},

		// 是否固定保障时间  true-固定  false-不固定
		fiexdEnableDate: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	computed: {
		// 起保日期选择配置
		startMaxDisabledTimeEnd() {
			return moment().add(this.insureTimeEnd, 'd');
		},
		startMinDisabledTimeStart() {
			return moment().add(this.insureTimeFirst, 'd').valueOf();
		},
		endMaxDisabledTimeEnd() {
			// 起保时间不存在的话，不做任何控制
			// 系统会默认设置起保时间，所以起保时间只有在页面初始化时短暂的不存在，用户操作时一定存在
			if (this.form.start && this.maxLengthPrice) {
				const start = new Date(`${this.form.start.slice(0, 10)} 00:00:00`).getTime();
				// 最晚可选日期 = 起保时间 + 最长保障期限 - 1
				return start + this.maxLengthPrice.maxLength - 86400000;
			} else {
				return false;
			}
		},
		endMinDisabledTimeStart() {
			if (this.form.start && this.maxLengthPrice) {
				return new Date(`${this.form.start.slice(0, 10)} 00:00:00`).getTime();
			} else {
				return false;
			}
		},
		planId() {
			return this.comboId();
		},
	},
	data() {
		return {
			form: {
				priceId: '',
				start: '',
				end: '',
				comboId: '',
				proId: '',
			},
			formShow: {
				priceId: '',
				start: '',
				end: '',
			},
			rules: {
				start: [{ required: true, message: '请选择起保日期', trigger: 'onChange' }],
				end: [{ required: true, message: '请选择终保日期', trigger: 'onChange' }],
				priceId: [{ required: true, message: '请选择保障期限', trigger: 'onChange' }],
			},
			priceIdPop: false, //计划保障弹框
			startDatePop: false, //开始日期弹框
			endDatePop: false, //终保日期弹框
			priceList: [], //保障期限
			insureTimeFirst: 0, // 最早投保时间 T + N
			insureTimeEnd: 0, // 最晚投保时间 T + N
			immediateExtendTime: 0, // 当天起保延长时间(分钟)
			immediatelyPayTimeEnd: 0, // 当天起保最晚购买时间
			immediateEndTimeType: 0, // 当天起保终保时间类型：0-当天24时止，1-合计24小时止
			noimmediatelyPayTimeEnd: 0, // 非当天起保最晚购买时间
			priceIsRelatedToNumberInsur: true, // 价格是否与被保人数量有关：true/false
			maxLengthPrice: undefined, // 最长保障期限的费率信息
			defaultIndex: 0,
		};
	},
	watch: {
		'form.priceId'(v) {
			let prices = this.priceList.filter(item => item.priceId === v);
			if (prices.length) {
				this.$store.commit('setPriceId', prices[0].priceId);
				this.$store.commit('setPrice', prices[0].price);
				//监听旅险
				this.getWatchForm(prices);
			}
		},
		//立牌需要监听调用接口
		'planId'() {
			this.handleData();
		},

		'form.start'(v){
			this.$store.commit('setStartTime', v);
		}
	},

	created() {
		this.setInitData();
		this.handleData();
	},

	methods: {
		// 根据保障计划获取数据
		async handleData() {
			const res = await http_getProductComboConfig({
				proId: this.proId(),
				comboId: this.comboId(),
			});
			this.form.proId = this.proId();
			this.form.comboId = this.comboId();

			this.priceList = res.insProductPrice2VoList.map(item => {
				item.maxLength = this.getPriceMaxLength(item);
				return item;
			});

			// 取最长保障期限费率（此处不能判断费率列表长度）
			this.setMaxLengthPrice();

			// 回显
			this.form.priceId = this.priceList[0].priceId;
			this.formShow.priceId = this.priceList[0].viewTime;
			this.priceChange();
		},

		// 设置投保参数
		setInitData() {
			let proInfo = this.productInfo();
			this.insureTimeFirst = proInfo.insureTimeFirst;
			this.insureTimeEnd = proInfo.insureTimeEnd;
			this.immediateExtendTime = proInfo.immediateExtendTime;
			this.immediatelyPayTimeEnd = proInfo.immediatelyPayTimeEnd;
			this.immediateEndTimeType = proInfo.immediateEndTimeType;
			this.noimmediatelyPayTimeEnd = proInfo.noimmediatelyPayTimeEnd;
			this.priceIsRelatedToNumberInsur = proInfo.priceIsRelatedToNumberInsur;
		},
		// 计算最长保障期限的毫秒长度
		getPriceMaxLength(priceInfo) {
			// 以时间零点开始用moment往后加时间，得出的时间戳就是毫秒长度
			let start = '1970-01-01 00:00:00';
			let momentAddUnit = 'd';
			switch (priceInfo.insureTimeUnit) {
				case '1':
					// 天
					momentAddUnit = 'd';
					break;

				case '2':
					// 月
					momentAddUnit = 'M';
					break;

				case '3':
					// 年
					momentAddUnit = 'y';
					break;

				default:
					break;
			}
			let stemp = moment.utc(start).add(Number(priceInfo.insureTime), momentAddUnit).valueOf();
			return stemp;
		},
		// 推算最长保障期限费率信息
		setMaxLengthPrice() {
			let maxStemp = -1;
			let maxPrice = undefined;
			for (let index = 0; index < this.priceList.length; index++) {
				const item = this.priceList[index];
				if (item.maxLength > maxStemp) {
					maxStemp = item.maxLength;
					maxPrice = item;
				}
			}
			this.maxLengthPrice = maxPrice;
		},
		// 根据计划设置时间
		priceChange() {
			if (!this.form.start) {
				// 设置起保时间与终保时间
				this.setStartTime();
			} else {
				// 仅设置终保时间
				this.setEndTime();
			}

			// 更新费率支持的保障年龄
			this.setProtectAge(this.form.priceId);
		},
		// 设置起保时间
		setStartTime(time = undefined) {
			// 入参，如果有入参，就是用户自己选择起保时间；如果没入参，就是设置默认起保时间

			// 先不固定时间为北京时间吧，element-ui不支持控制时区，如果要控制时区，还得换组件
			// 区分T+0与非T+0
			let start = NaN;

			if (time) {
				// 用户自己选择起保时间，日期是用户选择的，时间一定是00:00:00
				// 如果 time<当前时间，那用户选择的一定是今天，即时起保
				if (moment(time).valueOf() < Date.now()) {
					// 即时起保
					start = Date.now() + this.immediateExtendTime * 60 * 1000;
				} else {
					// 非即时起保
					start = time;
				}
			} else {
				// 默认起保时间
				// T+0时，就是当前时间 + 起保延长时间
				if (this.insureTimeFirst === 0) {
					// 即时起保
					start = Date.now() + this.immediateExtendTime * 60 * 1000;
				} else {
					// 非T+0
					start = moment().add(this.insureTimeFirst, 'd').format('YYYY-MM-DD') + ' 00:00:00';
				}
			}

			this.form.start = moment(start).format('YYYY-MM-DD HH:mm:ss');
			this.formShow.start = moment(start).format('YYYY/MM/DD');

			// 最后再根据保障期限设置终保时间
			this.setEndTime();
		},
		// // 起保时间改变
		startChange() {
			this.setStartTime(this.form.start);
		},

		// 设置终保时间
		setEndTime() {
			// 根据保障期限
			let checkPriceList = this.priceList.filter(item => item.priceId === this.form.priceId);
			if (checkPriceList.length) {
				let priceInfo = checkPriceList[0];
				// insureTimeUnit 1-天;2-月;3-年;4-终身;5-至被保人?周岁
				// 此处为旅意险，只处理天、月、年的情况
				let momentAddUnit = 'd';
				switch (priceInfo.insureTimeUnit) {
					case '1':
						// 天
						momentAddUnit = 'd';
						break;

					case '2':
						// 月
						momentAddUnit = 'M';
						break;

					case '3':
						// 年
						momentAddUnit = 'y';
						break;

					default:
						break;
				}

				let endTime = moment(this.form.start).add(Number(priceInfo.insureTime), momentAddUnit).format('YYYY-MM-DD HH:mm:ss');
				// 前端一律按照终保时间为当天24时止处理
				this.form.end = `${moment(endTime).subtract(1, 'd').format('YYYY-MM-DD')} 23:59:59`;
				this.formShow.end = `${moment(endTime).subtract(1, 'd').format('YYYY/MM/DD')}`;
				this.getDistanceDays();
			}
		},

		// 终保时间改变
		endChange() {
			// 1. 修正终保时间，手动选择的终保时间一定是当天的23:59:59
			this.form.end = `${this.form.end.slice(0, 10)} 23:59:59`;
			this.formShow.end=this.$base.dateFormater(new Date(this.form.end))

			// 2. 终保时间不影响起保时间，因为用户可以自己选择起保时间与终保时间，如果影响起保时间，那么用户就不能随意去选择终保时间

			// 3. 终保时间改变，自动调整最合适的保障期限

			// 起保时间与终保时间的时间差
			let diff = moment(this.form.end).diff(this.form.start);

			// 把费率列表按照保障期限毫秒长度从小到大进行排序，然后从头开始与时间差进行比较
			let priceList = this.priceList.sort((a, b) => {
				return a.maxLength - b.maxLength;
			});
			for (let index = 0; index < priceList.length; index++) {
				const item = priceList[index];
				if (diff <= item.maxLength) {
					this.isClickPrice = false;
					this.form.priceId = item.priceId;
					this.formShow.priceId = item.viewTime;
					break;
				}
			}
			this.getDistanceDays();
		},
		// 设置费率支持的保障年龄
		setProtectAge(priceId) {
			let price = this.priceList.filter(item => item.priceId === priceId)[0];
			if (price) {
				let min = Object.hasOwnProperty.call(price, 'ageBelong') ? price.ageBelong : undefined,
					max = Object.hasOwnProperty.call(price, 'ageBelong1') ? price.ageBelong1 : undefined;
				this.$store.commit('set_protectAgeMin', min);
				this.$store.commit('set_protectAgeMax', max);
			}
		},
		//获取旅险要监听的字段
		getWatchForm(prices) {
			const price = JSON.parse(JSON.stringify(prices));
			if (price[0].rateType == 1 && price[0].rateCalculationExpression) {
				const matches = price[0].rateCalculationExpression.match(/\b(?!(age|sex|price|profession)\b)[a-zA-Z]+\b/g);
				price[0].watchStr = matches;
			}
			this.$store.commit('setInsProductPrice2VoList', price[0]);
		},
		//开始日期
		startDateCheck(val) {
			this.form.start = `${this.$base.getDate(val)} 00:00:00`;
			this.formShow.start = this.$base.dateFormater(val);
			this.startDatePop = false;
			this.startChange();
		},
		// 终保日期
		endDateCheck(val) {
			this.form.end = `${this.$base.getDate(val)} ${this.$base.getTime(new Date())}`;
			this.formShow.end = this.$base.dateFormater(val);
			this.endDatePop = false;
			this.endChange();
		},
		// 保障期限
		onConfirm(val) {
			this.form.priceId = val.priceId;
			this.formShow.priceId = val.viewTime;
			this.priceIdPop = false;
			this.priceChange();
		},
		getData() {
			return new Promise(resolve => {
				this.$refs.planForm.validate().then(() => {
					resolve({ ...this.form });
				});
			});
		},
		handlePopup(item) {
			if (this.fixedEnableChange === 0 && item === 'priceIdPop') return false;
			if (this.fiexdEnableDate && item !== 'priceIdPop') return false;
			if (item === 'priceIdPop') {
				const index = this.priceList.findIndex(v => v.priceId === this.form.priceId);
				this.defaultIndex = index;
			}

			this[item] = true;
		},
		//计算天数
		getDistanceDays() {
			const start = new Date(this.form.start).getTime();
			const end = new Date(this.form.end).getTime();
			const days = Math.ceil((end - start) / (1000 * 60 * 60 * 24));
			this.$store.commit('setDays', days );
			this.$store.commit('setAllForm', { days });

		},
	},
};
</script>

<style lang="scss"></style>
