<template>
	<div>
		<van-form ref="planForm" input-align="right" error-message-align="right" :scroll-to-error="true">
			<van-field label="保障期限" :value="formShow.priceId" label-class="cell_title" value-class="cell_value" :border="false" readonly is-link clickable name="picker" placeholder="请选择保障期限" @click="handlePopup('priceIdPop')" :rules="rules.priceId" />
			<van-field label="起保日期" :value="formShow.start" label-class="cell_title" value-class="cell_value" :border="false" readonly is-link clickable name="calendar" placeholder="请选择起保日期" @click="handlePopup('startDatePop')" :rules="rules.start" />
			<van-field label="终保日期" :value="formShow.end" label-class="cell_title" value-class="cell_value" :border="false" readonly is-link clickable name="calendar" placeholder="请选择终保日期" @click="handlePopup('endDatePop')" :rules="rules.end" />
			<!-- 起保日期 -->
			<van-calendar v-model="startDatePop" :default-date="new Date(form.start)" @confirm="startDateCheck" :min-date="new Date(this.startMinDisabledTimeStart)" :max-date="new Date(this.startMaxDisabledTimeEnd)" color="#2594EF" :formatter="$base.calendarFormatter" />
			<!-- 终保日期 -->
			<van-calendar v-model="endDatePop" @confirm="endDateCheck" :default-date="new Date(form.end)" :min-date="new Date(this.endMinDisabledTimeStart)" :max-date="new Date(this.endMaxDisabledTimeEnd)" color="#2594EF" :formatter="$base.calendarFormatter" />
			<!-- 保障期限 -->
			<van-popup v-model="priceIdPop" position="bottom" :style="{ height: '30vh' }">
				<van-picker v-if="priceIdPop" title="保障期限" :default-index="defaultIndex" show-toolbar value-key="viewTime" :visible-item-count="3" :columns="priceList" @confirm="onConfirm" @cancel="priceIdPop = false" />
			</van-popup>
		</van-form>
	</div>
</template>

<script>
import moment from 'moment/moment';
import { Form, Field, Calendar, Popup, Picker, Button } from 'vant';
import { http_getProductComboConfig } from '@/request/insureV2.js';
export default {
	inject: ['proId', 'comboId', 'productInfo', 'productCorrelationList'],
	components: {
		[Form.name]: Form,
		[Field.name]: Field,
		[Calendar.name]: Calendar,
		[Popup.name]: Popup,
		[Picker.name]: Picker,
		[Button.name]: Button,
	},
	computed: {
		// 起保日期选择配置
		startMaxDisabledTimeEnd() {
			return moment().add(this.insureTimeEnd, 'd');
		},
		startMinDisabledTimeStart() {
			return moment().add(this.insureTimeFirst, 'd').valueOf();
		},
		endMaxDisabledTimeEnd() {
			// 起保时间不存在的话，不做任何控制
			// 系统会默认设置起保时间，所以起保时间只有在页面初始化时短暂的不存在，用户操作时一定存在
			if (this.form.start && this.maxLengthPrice) {
				const start = new Date(`${this.form.start.slice(0, 10)} 00:00:00`).getTime();
				// 最晚可选日期 = 起保时间 + 最长保障期限 - 1
				return start + this.maxLengthPrice.maxLength - 86400000;
			} else {
				return false;
			}
		},
		endMinDisabledTimeStart() {
			if (this.form.start && this.maxLengthPrice) {
				return new Date(`${this.form.start.slice(0, 10)} 00:00:00`).getTime();
			} else {
				return false;
			}
		},
		planId() {
			return this.comboId();
		},
	},
	data() {
		return {
			form: {
				priceId: '',
				start: '',
				end: '',
				comboId: '',
				proId: '',
			},
			formShow: {
				priceId: '',
				start: '',
				end: '',
			},
			rules: {
				start: [{ required: true, message: '请选择起保日期', trigger: 'onChange' }],
				end: [{ required: true, message: '请选择终保日期', trigger: 'onChange' }],
				priceId: [{ required: true, message: '请选择保障期限', trigger: 'onChange' }],
			},
			priceIdPop: false, //计划保障弹框
			startDatePop: false, //开始日期弹框
			endDatePop: false, //终保日期弹框
			priceList: [], //保障期限
			insureTimeFirst: 0, // 最早投保时间 T + N
			insureTimeEnd: 0, // 最晚投保时间 T + N
			immediateExtendTime: 0, // 当天起保延长时间(分钟)
			immediatelyPayTimeEnd: 0, // 当天起保最晚购买时间
			immediateEndTimeType: 0, // 当天起保终保时间类型：0-当天24时止，1-合计24小时止
			noimmediatelyPayTimeEnd: 0, // 非当天起保最晚购买时间
			priceIsRelatedToNumberInsur: true, // 价格是否与被保人数量有关：true/false
			maxLengthPrice: undefined, // 最长保障期限的费率信息
			allPriceList: [],
			execute: {
				等于: '==',
				不等于: '!=',
				大于: '>',
				小于: '<',
				大于等于: '>=',
				小于等于: '<=',
			},
			defaultIndex: 0,
			comboIdPriceList: [], //关联产品计划id的对应的所有保障期限
			selComboIdPriceList: [], //选中的保障期限
		};
	},
	watch: {
		'form.priceId'(v, oldV) {
			let prices = this.priceList.filter(item => item.priceId === v);

			if (prices.length) {
				this.$store.commit('setPriceId', prices[0].priceId);
				this.$store.commit('setPrice', prices[0].price);
			}
			let next = true;
			this.priceList.forEach(item => {
				if (item.priceId !== prices.priceId && item.viewTime === prices[0].viewTime) {
					next = false;
				}
			});
			if (!next && oldV) {
				this.getRulePrice(false);
			}
		},
		//立牌需要监听调用接口
		'planId'() {
			this.handleData();
		},
	},

	created() {
		this.setInitData();
		this.handleData();
	},
	methods: {
		// 根据保障计划获取数据
		async handleData() {
			const res = await http_getProductComboConfig({
				proId: this.proId(),
				comboId: this.comboId(),
			});
			const productCorrelationList = this.productCorrelationList();
			//如果有关联产品，对关联产品进行处理
			if (productCorrelationList) {
				let comboIdPriceList = [];
				const comboIdList = this.handleCorreLation(this.proId(), this.comboId());
				for (let item of comboIdList) {
					const data = await http_getProductComboConfig({ proId: item.targetProId, comboId: item.targetComboId });
					if (data.insProductPrice2VoList.length > 0) {
						comboIdPriceList.push(data.insProductPrice2VoList);
					}
				}
				this.comboIdPriceList = comboIdPriceList;
			}
			this.form.proId = this.proId();
			this.form.comboId = this.comboId();

			this.priceList = res.insProductPrice2VoList.map(item => {
				item.maxLength = this.getPriceMaxLength(item);
				return item;
			});

			// 取最长保障期限费率（此处不能判断费率列表长度）
			this.setMaxLengthPrice();
			// 回显
			this.form.priceId = this.priceList[0].priceId;
			this.formShow.priceId = this.priceList[0].viewTime;
			this.priceChange();
			//校验表达式匹配价格
			this.handlePriceExecute();
		},

		//如果有关联产品，对关联产品进行处理
		handleCorreLation(proId, comboId) {
			// 如果有附加险遍历获取所有附加险的保障计划
			const productCorrelationList = this.productCorrelationList();
			let data = [];
			if (productCorrelationList[proId]) {
				productCorrelationList[proId].forEach(item => {
					const list = item.comboIdList.filter(item => item.sourceComboId == comboId);
					if (list.length) {
						data.push(list[0]);
					}
					if (productCorrelationList[item.targetProId]) {
						const result = this.handleCorreLation(item.targetProId, list[0]?.targetComboId);
						data = [...result, ...data];
					}
				});
			}
			return data;
		},

		// 设置投保参数
		setInitData() {
			let proInfo = this.productInfo();
			this.insureTimeFirst = proInfo.insureTimeFirst;
			this.insureTimeEnd = proInfo.insureTimeEnd;
			this.immediateExtendTime = proInfo.immediateExtendTime;
			this.immediatelyPayTimeEnd = proInfo.immediatelyPayTimeEnd;
			this.immediateEndTimeType = proInfo.immediateEndTimeType;
			this.noimmediatelyPayTimeEnd = proInfo.noimmediatelyPayTimeEnd;
			this.priceIsRelatedToNumberInsur = proInfo.priceIsRelatedToNumberInsur;
		},
		// 计算最长保障期限的毫秒长度
		getPriceMaxLength(priceInfo) {
			// 以时间零点开始用moment往后加时间，得出的时间戳就是毫秒长度
			let start = '1970-01-01 00:00:00';
			let momentAddUnit = 'd';
			switch (priceInfo.insureTimeUnit) {
				case '1':
					// 天
					momentAddUnit = 'd';
					break;

				case '2':
					// 月
					momentAddUnit = 'M';
					break;

				case '3':
					// 年
					momentAddUnit = 'y';
					break;

				default:
					break;
			}
			let stemp = moment.utc(start).add(Number(priceInfo.insureTime), momentAddUnit).valueOf();
			return stemp;
		},
		// 推算最长保障期限费率信息
		setMaxLengthPrice() {
			let maxStemp = -1;
			let maxPrice = undefined;
			for (let index = 0; index < this.priceList.length; index++) {
				const item = this.priceList[index];
				if (item.maxLength > maxStemp) {
					maxStemp = item.maxLength;
					maxPrice = item;
				}
			}
			this.maxLengthPrice = maxPrice;
		},
		// 根据计划设置时间
		priceChange() {
			if (!this.form.start) {
				// 设置起保时间与终保时间
				this.setStartTime();
			} else {
				// 仅设置终保时间
				this.setEndTime();
			}
			// 更新费率支持的保障年龄
			this.setProtectAge(this.form.priceId);
		},
		// 设置起保时间
		setStartTime(time = undefined) {
			// 入参，如果有入参，就是用户自己选择起保时间；如果没入参，就是设置默认起保时间

			// 先不固定时间为北京时间吧，element-ui不支持控制时区，如果要控制时区，还得换组件
			// 区分T+0与非T+0
			let start = NaN;

			if (time) {
				// 用户自己选择起保时间，日期是用户选择的，时间一定是00:00:00
				// 如果 time<当前时间，那用户选择的一定是今天，即时起保
				if (moment(time).valueOf() < Date.now()) {
					// 即时起保
					start = Date.now() + this.immediateExtendTime * 60 * 1000;
				} else {
					// 非即时起保
					start = time;
				}
			} else {
				// 默认起保时间
				// T+0时，就是当前时间 + 起保延长时间
				if (this.insureTimeFirst === 0) {
					// 即时起保
					start = Date.now() + this.immediateExtendTime * 60 * 1000;
				} else {
					// 非T+0
					start = moment().add(this.insureTimeFirst, 'd').format('YYYY-MM-DD') + ' 00:00:00';
				}
			}

			this.form.start = moment(start).format('YYYY-MM-DD HH:mm:ss');
			this.formShow.start = moment(start).format('YYYY/MM/DD');

			// 最后再根据保障期限设置终保时间
			this.setEndTime();
		},
		// // 起保时间改变
		startChange() {
			this.setStartTime(this.form.start);
		},

		// 设置终保时间
		setEndTime() {
			// 根据保障期限
			let checkPriceList = this.priceList.filter(item => item.priceId === this.form.priceId);
			if (checkPriceList.length) {
				let priceInfo = checkPriceList[0];
				// insureTimeUnit 1-天;2-月;3-年;4-终身;5-至被保人?周岁
				// 此处为旅意险，只处理天、月、年的情况
				let momentAddUnit = 'd';
				switch (priceInfo.insureTimeUnit) {
					case '1':
						// 天
						momentAddUnit = 'd';
						break;

					case '2':
						// 月
						momentAddUnit = 'M';
						break;

					case '3':
						// 年
						momentAddUnit = 'y';
						break;

					default:
						break;
				}

				let endTime = moment(this.form.start).add(Number(priceInfo.insureTime), momentAddUnit).format('YYYY-MM-DD HH:mm:ss');
				// 前端一律按照终保时间为当天24时止处理
				this.form.end = `${moment(endTime).subtract(1, 'd').format('YYYY-MM-DD')} 23:59:59`;
				this.formShow.end = `${moment(endTime).subtract(1, 'd').format('YYYY/MM/DD')}`;
			}
		},

		// 终保时间改变
		endChange() {
			// 1. 修正终保时间，手动选择的终保时间一定是当天的23:59:59
			this.form.end = `${this.form.end.slice(0, 10)} 23:59:59`;
			this.formShow.end = this.$base.dateFormater(new Date(this.form.end));

			// 2. 终保时间不影响起保时间，因为用户可以自己选择起保时间与终保时间，如果影响起保时间，那么用户就不能随意去选择终保时间

			// 3. 终保时间改变，自动调整最合适的保障期限

			// 起保时间与终保时间的时间差
			let diff = moment(this.form.end).diff(this.form.start);

			// 把费率列表按照保障期限毫秒长度从小到大进行排序，然后从头开始与时间差进行比较
			let priceList = this.priceList.sort((a, b) => {
				return a.maxLength - b.maxLength;
			});
			for (let index = 0; index < priceList.length; index++) {
				const item = priceList[index];
				if (diff <= item.maxLength) {
					this.isClickPrice = false;
					this.form.priceId = item.priceId;
					this.formShow.priceId = item.viewTime;
					break;
				}
			}
		},
		// 设置费率支持的保障年龄
		setProtectAge(priceId) {
			let price = this.priceList.filter(item => item.priceId === priceId)[0];
			if (price) {
				let min = Object.hasOwnProperty.call(price, 'ageBelong') ? price.ageBelong : undefined,
					max = Object.hasOwnProperty.call(price, 'ageBelong1') ? price.ageBelong1 : undefined;
				this.$store.commit('set_protectAgeMin', min);
				this.$store.commit('set_protectAgeMax', max);
			}
		},

		//开始日期
		startDateCheck(val) {
			this.form.start = `${this.$base.getDate(val)} 00:00:00`;
			this.formShow.start = this.$base.dateFormater(val);
			this.startDatePop = false;
			this.startChange();
		},
		// 终保日期
		endDateCheck(val) {
			this.form.end = `${this.$base.getDate(val)} ${this.$base.getTime(new Date())}`;
			this.formShow.end = this.$base.dateFormater(val);
			this.endDatePop = false;
			this.endChange();
		},
		// 保障期限
		onConfirm(val) {
			this.form.priceId = val.priceId;
			this.formShow.priceId = val.viewTime;
			this.priceIdPop = false;
			this.priceChange();
			this.getRulePrice();
		},
		getData() {
			return new Promise(resolve => {
				this.$refs.planForm.validate().then(() => {
					resolve({ ...this.form, selComboIdPriceList: this.selComboIdPriceList });
				});
			});
		},
		//处理当前的计算费率表达式
		handlePriceExecute() {
			const priceList = JSON.parse(JSON.stringify(this.priceList));
			const watchType = new Set(); //根据校验规则匹配要监听的字段
			priceList.forEach(item => {
				if (item.rateCalculationExpression) {
					const executeNumber = item.rateCalculationExpression.match(/\b(?!price\b)(?!\d+)\w+\b/g);
					executeNumber.forEach(v => {
						watchType.add(v);
					});
				}
				const rateVerificationRuleDtoList = item?.rateVerificationExpressionDto?.rateVerificationRuleDtoList;
				const execute = item?.rateVerificationExpressionDto?.execute.replace(/\s/g, '').replace(/and/g, '&&').replace(/or/g, '||');
				let executeStr = '';
				item.executeFieldName = [];

				//根据要执行的校验表达式去拿校验字段进行监听
				if (execute) {
					const executeNumber = execute.match(/\d+/g);
					executeNumber.forEach(item => {
						const data = rateVerificationRuleDtoList.filter(v => v.index === Number(item))[0];
						watchType.add(data.fieldName);
					});

					for (let i = 0; i < execute.length; i++) {
						if (!isNaN(execute[i])) {
							const data = rateVerificationRuleDtoList.filter(v => v.index === Number(execute[i]))[0];

							if (data.operator === '包含') {
								let str = `${data.fieldName}&&`;
								data.fieldValue.split(',').forEach(item => {
									str += `${data.fieldName}.includes("${item}")&&`;
								});
								executeStr += `(${str.slice(0, -2)})`;
							} else if (data.operator === '不包含') {
								let str = `${data.fieldName}&&`;
								data.fieldValue.split(',').forEach(item => {
									str += `!${data.fieldName}.includes("${item}")&&`;
								});
								executeStr += `(${str.slice(0, -2)})`;
							} else {
								if (data.fieldValue === 'NULL') {
									executeStr += `${data.fieldName}${this.execute[data.operator]}${data.fieldValue.toLowerCase()}`;
								} else {
									executeStr += `${data.fieldName}${this.execute[data.operator]}${data.fieldValue}`;
								}
							}

							item.executeFieldName.push(data.fieldName);
						} else {
							executeStr += execute[i];
						}
					}
					item.executeStr = executeStr;
				}
			});
			console.log('保障期限=====', priceList);
			this.allPriceList = priceList;

			this.watchForm(Array.from(new Set(watchType)));
		},

		//监听对应的form改变
		watchForm(watchTypes) {
			let prices = this.priceList.filter(item => item.priceId === this.form.priceId);
			this.$store.commit('setAllPrice', prices[0].price);
			if (watchTypes.length) {
				watchTypes.forEach(v => {
					this.$watch(
						() => this.$store.state.insuranceV2.allForm[v],
						function (newValue, oldValue) {
							if (newValue !== oldValue) {
								console.log(v + '发生了变化', newValue);
								let prices = this.priceList.filter(item => item.priceId === this.form.priceId)[0];
								let next = false;
								this.priceList.forEach(item => {
									if (item.priceId !== this.form.priceId && item.viewTime === prices.viewTime) {
										next = true;
									}
								});
								this.getRulePrice(!next);
							}
						},
						{ immediate: true },
					);
				});
			}
		},
		//命中规则
		async getRulePrice(next = false) {
			const priceList = this.allPriceList;
			const textFile = JSON.parse(JSON.stringify(this.$store.state.insuranceV2.allForm));

			for (let item in textFile) {
				if (textFile[item] == 'true') {
					textFile[item] = true;
				}
				if (textFile[item] == 'false') {
					textFile[item] = false;
				}
				if (textFile[item].length === 0 || textFile[item] === '' || textFile[item] === undefined) {
					textFile[item] = null;
				}
			}
			let prices = this.priceList.filter(item => item.priceId === this.form.priceId)[0];
			let price = prices?.price;
			let correlationPrice = 0; //关联产品价格
			if (next) {
				if (prices?.rateCalculationExpression) {
					price = this.handleBasicRate(prices, textFile);
				} else if (this.comboIdPriceList?.length > 0) {
					const index = this.priceList.findIndex(item => item.priceId === prices?.priceId);
					if (index > -1) {
						correlationPrice = this.handleCorreLationPrice(index, textFile);
					}
				}
				this.$store.commit('setAllPrice', price + correlationPrice);

				return;
			}
			let flag = false;
			for (let i in priceList) {
				let str = priceList[i].executeStr;
				priceList[i].executeFieldName.forEach(item => {
					if (textFile.hasOwnProperty(item)&&(typeof str=="string")) {
						str = str.replace(new RegExp('\\b' + item + '\\b', 'g'), `textFile.${item}`);
					} else {
						str = false;
					}
				});
				flag = eval(str);
				if (flag && priceList[i].viewTime == prices.viewTime) {
					let price = priceList[i].price;
					if (priceList[i].rateCalculationExpression) {
						price = this.handleBasicRate(priceList[i], textFile);
					} else if (this.comboIdPriceList?.length > 0) {
						// 如果关联产品存在
						correlationPrice = this.handleCorreLationPrice(i, textFile);
					}
					this.form.priceId = priceList[i].priceId;
					this.$store.commit('setAllPrice', price + correlationPrice);

					this.priceChange();
					break;
				}
				// 没有命中也要设置价格
				if (!flag) {
					if (prices?.rateCalculationExpression) {
						price = this.handleBasicRate(prices, textFile);
					} else if (this.comboIdPriceList?.length > 0) {
						// 如果关联产品存在
						const index = this.priceList.findIndex(item => item.priceId === prices.priceId);

						correlationPrice = this.handleCorreLationPrice(index, textFile);
					}
					this.$store.commit('setAllPrice', price + correlationPrice);
				}
			}
		},
		// 计算基础费率
		handleBasicRate(prices, textFile) {
			let price = prices.price;
			let str = prices.rateCalculationExpression;
			const executeNumber = new Set(Array.from(prices.rateCalculationExpression.match(/\b(?!price\b)(?!\d+)\w+\b/g)));
			executeNumber.forEach(v => {
				if (textFile.hasOwnProperty(v)) {
					str = str.replace(new RegExp(`\\b${v}\\b`, 'g'), `textFile.${v}`);
				}
			});
			str = str.replace(/price/g, 'prices.price');
			price = eval(str);
			let correlationPrice = 0;

			//如果有关联产品
			if (this.comboIdPriceList?.length > 0) {
				const index = this.priceList.findIndex(item => item.priceId === prices?.priceId);
				if (index > -1) {
					correlationPrice = this.handleCorreLationPrice(index, textFile);
				}
			}
			return price + correlationPrice;
		},
		//计算关联产品总价
		handleCorreLationPrice(i, allFileData) {
			const selComboIdPriceList = [];
			this.comboIdPriceList.forEach(v => {
				v[Number(i)] && selComboIdPriceList.push(v[Number(i)]);
			});
			let price = 0;
			if (selComboIdPriceList.length > 0) {
				for (let item of selComboIdPriceList) {
					// 是否有基础费率
					if (item.rateCalculationExpression) {
						const executeNumber = new Set(Array.from(item.rateCalculationExpression.match(/\b(?!price\b)(?!\d+)\w+\b/g)));
						let str = item.rateCalculationExpression;
						executeNumber.forEach(v => {
							if (allFileData.hasOwnProperty(v)) {
								str = str.replace(new RegExp(`\\b${v}\\b`, 'g'), `allFileData.${v}`);
							}
						});
						str = str.replace(/price/g, 'item.price');
						price = price + eval(str);
					} else {
						price = price + item.price;
					}
				}
			}
			this.selComboIdPriceList = selComboIdPriceList;
			this.$store.commit('setSelComboIdPriceList', selComboIdPriceList);

			return price;
		},
		handlePopup(item) {
			if (item === 'priceIdPop') {
				const index = this.priceList.findIndex(v => v.priceId === this.form.priceId);
				this.defaultIndex = index;
			}
			this[item] = true;
		},
	},
};
</script>

<style lang="scss" scoped></style>
